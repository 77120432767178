import React from 'react';
import { FaRegLightbulb } from "react-icons/fa";
import { MdOutlineHandshake } from "react-icons/md";
import { IoPricetagsOutline } from "react-icons/io5";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { FaHeadphonesAlt } from "react-icons/fa";
import { BsTools } from "react-icons/bs";
import { GrDocumentText } from "react-icons/gr";
import { PiChatsTeardropBold } from "react-icons/pi";
import Translator from '../../components/I18n/Translator';
import ImageLoading from '../../components/ImageLoading';



function AboutSections() {
    return (
        <div className='about-section'>
            <br />
            <div className="wrapper">
                <div className="about-content">
                    <div className="image">
                     <ImageLoading source={require("../../images/company/12.jpg")} alt="" />
                    </div> 
                    <div className="text-section">
                        <div className="title-info">
                            <h1 className="title"><Translator path={"home.home_text_area1.title"} /></h1>
                            <div className="bar"></div>
                        </div>
                        <p>
                        <Translator path={"home.home_text_area1.text1"} /> <strong><Translator path={"home.home_text_area1.text2"} /></strong>
                         <Translator path={"home.home_text_area1.text3"} /><strong><Translator path={"home.home_text_area1.text4"} /> </strong> <Translator path={"home.home_text_area1.text5"} />
                         <br /> <br />
                         <Translator path={"home.home_text_area1.text6"} />
                         <br /> <br />
                         <Translator path={"home.home_text_area1.text7"} />
                        </p>
                        <div className="details">
                            <div className="block-item">
                                <div className="icon"><FaRegLightbulb /></div>
                                <span><Translator path={"home.circles_text.text1"} /></span>
                            </div>
                            <div className="block-item">
                                <div className="icon"><MdOutlineHandshake /></div>
                                <span><Translator path={"home.circles_text.text2"} /></span>
                            </div>
                            <div className="block-item">
                                <div className="icon"> <IoPricetagsOutline /></div>
                                <span><Translator path={"home.circles_text.text3"} /></span>
                            </div>
                        </div>

                    </div>
                </div>
                <br /> 
                <div className="about-data-info">
                    <div className="text">
                        <div className="title-info">
                            <strong><Translator path={"home.home_text_area2.small"} /></strong>
                            <h1 className='title'><Translator path={"home.home_text_area2.title"} /></h1>
                            <div className="bar"></div>
                        </div>
                        <p><Translator path={"home.home_text_area2.text1"} /></p>
                        <br />
                        <p><Translator path={"home.home_text_area2.text2"} /></p>
                        <br />
                        <p>
                          <Translator path={"home.home_text_area2.text3"} />
                          <a style={{marginRight:"8px"}} href="https://store.exportech.com.pt/" target="_blank" rel="noopener noreferrer">
                               <em> <Translator path={"home.home_text_area2.text4"} />  </em> 
                            </a> <Translator path={"home.home_text_area2.text5"} />
                        </p>  
                    </div>
                    <div className="boxes">
                        <div className="box">
                            <div className="icon"><FaHeadphonesAlt /></div>
                            <strong><Translator path={"home.boxes_area.box1.title"} /></strong> 
                            <span><Translator path={"home.boxes_area.box1.description"} /></span>
                        </div>
                        <div className="box">
                            <div className="icon"><BsTools /></div>
                            <strong><Translator path={"home.boxes_area.box2.title"} /></strong> 
                            <span><Translator path={"home.boxes_area.box2.description"} /></span>
                        </div>
                        <div className="box">
                            <div className="icon"><GrDocumentText /></div>
                            <strong><Translator path={"home.boxes_area.box3.title"} /></strong>
                            <p></p>
                            <span>
                                <Translator path={"home.boxes_area.box3.description1"} /> 
                                <a href={require("../../RMA2024.pdf")} download style={{textDecoration:"underline", color:"#0473eb"}} className='text-main'>
                                    <Translator path={"home.boxes_area.box3.description2"} />
                                </a>   
                                <Translator path={"home.boxes_area.box3.description3"} />
                            </span>
                        </div>
                        <div className="box">
                            <div className="icon"><PiChatsTeardropBold /></div>
                            <strong><Translator path={"home.boxes_area.box4.title"} /></strong>
                            <p>+351 210 353 555</p>
                            <p>helpdesk@exportech.com.pt</p>
                            <p>geral@exportech.com.pt</p> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSections
